@font-face {
  font-family: 'Bower';
  src: url(../assets/fonts/Bower-Bold.woff) format('woff');
}

@font-face {
  font-family: 'McKinseySans';
  src: url(../assets/fonts/McKinseySans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Theinhardt Pan';
  src: url('../assets/fonts/TheinhardtPan-Regular.woff') format('woff');
}