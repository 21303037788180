@charset "UTF-8";

.header {
  .left {
    &:first-of-type {
      &::before {
        background-color: transparent !important;
      }
    }
  }
}
